.accordion-box {
  &+& {
    margin-top: sp-vw(52);
    @include wide {
      margin-top: 45px;
    }
  }
}
.accordion-box__head {
  @include sp {
    border-bottom: 1px solid $border-color4;
    padding-bottom: sp-vw(12);
    padding-right: sp-vw(100);
    position: relative;
    min-height: sp-vw(80);
    display: flex;
    align-items: flex-end;
  }
}
.accordion-box__title {
  color:#002D83;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.065em;
  position: relative;
  margin-top: -2px;
  @include wide {
    font-size: 1.6rem;
    padding-left: 12px;
    letter-spacing: 0.08em;
    &::before {
      content: '';
      display: block;
      border-left: 4px solid $border-color4;
      height: 18px;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
}
.accordion-box__toggle-icon {
  width: sp-vw(80);
  height: sp-vw(80);
  @include main_gradient;
  position: absolute;
  right: 0;
  bottom: 0;
  @include wide {
    display: none;
  }
  &::before ,
  &::after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: $transition-base;
    @at-root .accordion-box.is-show & {
      transform: translate(-50%,-50%) rotate(90deg);
    }
  } 
  &::before {
    width: sp-vw(30);
    height:1px;
    @at-root .accordion-box.is-show & {
      opacity: 0;
    }
  }
  &::after {
    width:1px;
    height: sp-vw(30);
  }
}
.accordion-box__body {
  @include sp {
    margin-top: sp-vw(20);
    text-align: justify;
    transition:opacity $transition-base;
    .accordion-box:not(.is-show) & {
      height: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      margin-top: 0;
    }
  }
  @include wide {
    margin-top: 4px;
    line-height: 2;
  }
}