.business-menu {
	background:url("/assets/img/common/bg_business-menu_sp.jpg") #002D83 no-repeat center top / 100% auto;
	padding:sp-vw(128) 0 sp-vw(166) 0;
	@include wide {
		padding: 85px 0 70px;
		background:url("/assets/img/common/bg_business-menu_pc.jpg") no-repeat center center / cover;
	}
	.inner {
		@include sp {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
.business-menu__heading {
	margin-bottom: sp-vw(80);
	@include wide {
		margin-bottom: 60px;
	}
	em,span {
		color: #fff;
	}
}
.business-menu__list {
	@include wide {
		display: flex;
		gap:8px;
	}
}
.business-menu__list__item {
	filter: drop-shadow(sp-vw(5) sp-vw(5) sp-vw(20) rgba(0, 0, 0, 0.4));
	position: relative;
	@include sp {
		width: sp-vw(628);
		margin-top: sp-vw(52);
		&:nth-child(even) {
			margin-left: auto;
		}
	}
	@include wide {
		width: 100%;
		height: 272px;
		&:nth-child(even) {
			margin-top: 40px;
		}
		&:hover {
			z-index: 3;
		}
	}
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: #fff;
		z-index:0;
	}
	&::after {
		@include wide {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@include main_gradient_reverse;
			z-index:1;
			opacity: 0;
			transition: $transition-base;
		}
	}
	&:hover{
		&::before {
			@include wide {
				opacity: 0;
			}
		}
		&::after {
			@include wide {
				opacity: 1;
				transform: scale(1.09,1.09);
			}
		}
	}
}
.business-menu__list__item__inner {
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
	@include sp {
		flex-direction: row-reverse;
		padding: 0 0 0 sp-vw(48);
		height: sp-vw(184);
	}
	@include wide {
		flex-direction: column;
		height: 100%;
		&:hover {
			background: none;
			opacity: 0.7;
		}
	}
}
.business-menu__list__title {
	letter-spacing: 0.06em;
	flex:1;
	@include wide {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	em {
		display: block;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 600;
		color:$main-color3;
	}
	small {
		display: block;
		font-family: $font-en1;
		color:$main-color4;
		font-size: 1.2rem;
		font-weight: 600;
		@include wide {
			margin-top: 5px;
		}
	}
	.business-menu__list__item:hover & {
		em,small {
			@include wide {
				color:#FFF;
			}
		}
	}
}
.business-menu__list__icon {
	flex:none;
	@include sp {
		width: sp-vw(120);
		height: sp-vw(100);
		margin-right: sp-vw(35);
		margin-top: sp-vw(-38);
	}
	@include wide {
		width: 65px;
		height: 65px;
		margin:35px auto 0;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		filter: invert(49%) sepia(41%) saturate(284%) hue-rotate(177deg) brightness(89%) contrast(89%);
	}
	.business-menu__list__item:hover & {
		img {
			@include wide {
				filter: invert(100%) sepia(26%) saturate(361%) hue-rotate(226deg) brightness(110%) contrast(97%);
			}
		}
	}
}
.business-menu__list__button {
	font-size: 1.5rem;
	letter-spacing: 0.06em;
	@include main_gradient_reverse;
	padding-left: 0;
	justify-content: center;
	flex:none;
	@include sp {
		width: sp-vw(304);
		height: sp-vw(80);
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translateY(50%);
		padding-bottom: sp-vw(5);
	}
	@include wide {
		width: 100%;
		height: 40px;
		margin-top: auto;
		padding-right: 25px;
		a:hover & {
			background: none;
		}
		&::before {
			content: none;
		}
	}
	&::after {
		position: static;
		margin-left: sp-vw(20);
		margin-top: 0;
		@include wide {
			margin-left:9px;
		}
	}
}