.pager {
	.wp-pagenavi {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.previouspostslink,
	.nextpostslink {
		width: sp-vw(60);
		height: sp-vw(60);
		text-indent: 999px;
		overflow: hidden;
		position: absolute;
		@include wide {
			width: 50px;
			height: 30px;
		}
		&:after {
			content: '';
			display: block;
			width: sp-vw(20);
			height: sp-vw(20);
			border:solid $text-color2;
			position: absolute;
			top: sp-vw(20);
			border-width:0 0 1px 1px;
			@include wide {
				width: 10px;
				height: 10px;
				top: 10px;
			}
		}
	}
	
	.previouspostslink {
		top: sp-vw(16);
		left: 50%;
		transform: translateX(sp-vw(-200));
		
		@include wide {
			top: 13px;
			left: 50%;
			transform: translateX(-118px);
		}



		&:after {
			left: 32%;
			transform: rotate(45deg);

			@include wide {
				left: 53%;
			}
		}
	}
	.nextpostslink {
		top: sp-vw(16);
		right: 50%;
		transform: translateX(sp-vw(200));

		@include wide {
			top: 13px;
			right: 50%;
			transform: translateX(119px);
		}

		&:after {
			right: 30%;
			transform: rotate(-135deg);

			@include wide {
				right: 53%;
			}
		}
	}

	// 最初に戻る、最後に送る
	.first,
	.last {
		width: sp-vw(80);
		height: sp-vw(60);
		text-indent: 999px;
		overflow: hidden;
		position: absolute;
		@include wide {
			width: 50px;
			height: 30px;
		}
		&:after {
			content: '';
			display: block;
			width: sp-vw(20);
			height: sp-vw(20);
			border:solid $text-color2;
			position: absolute;
			top: sp-vw(20);
			border-width:0 0 1px 1px;
			@include wide {
				width: 10px;
				height: 10px;
				top: 10px;
			}
		}

		&::before {
			content: '';
			display: block;
			width: sp-vw(20);
			height: sp-vw(20);
			border:solid $text-color2;
			position: absolute;
			top: sp-vw(20);
			border-width:0 0 1px 1px;
			@include wide {
				width: 10px;
				height: 10px;
				top: 10px;
			}
		}
	}
	.first {
		top: sp-vw(16);
		left: 50%;
		transform: translateX(sp-vw(-279));

		@include wide {
			top: 13px;
			left: 50%;
			transform: translateX(-168px);
		}


		&:after,	
		&::before {
			left: 23%;
			transform: rotate(45deg);
			
			@include wide {
				left: 72%;
			}
		}

		&::before {
			left: 11%;

			@include wide {
				left: 63%;
			}
		}
	}
	.last {
		top: sp-vw(16);
		right: 50%;
		transform: translateX(sp-vw(279));

		@include wide {
			top: 13px;
			right: 50%;
			transform: translateX(168px);
		}

		&:after,
		&::before {
			right: 21%;
			transform: rotate(-135deg);
			
			@include wide {
				right: 72%;
			}
		}
		&::before {
			right: 9%;

			@include wide {
				right: 63%;
			}
		}
	}
	.page,.current {
		color:$main-color3;
		font-size: 2rem;
		text-align: center;
		padding:sp-vw(10) sp-vw(16);
		@include wide {
			padding:10px 8px;
		}
		&:after {
			content: '';
			display: block;
			width: sp-vw(54);
			height: sp-vw(6);
			background: $border-color2;
			margin-top: sp-vw(4);
			@include wide {
				width: 27px;
				height: 3px;
				margin-top: 2px;
			}
		}
	}
	.page:hover,
	.current {
		&:after {
			background: linear-gradient(90deg, #0f74ca 0%, #1c35ad 100%);
		}
	}
}

// .pager__number {
// 	display: flex;
// 	justify-content: center;
// 	padding:0 sp-vw(14);
// 	@include wide {
// 		padding:0 5px;
// 	}
// }