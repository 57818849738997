.subpage-menu {
  padding: sp-vw(120) 0 sp-vw(120);
  position: relative;
  @include wide {
    padding-top: 95px;
    padding-bottom: 100px;
  }
}
.subpage-menu__heading {
  margin-bottom: sp-vw(55);
  @include wide {
    margin-bottom: 36px;
  }
}
.subpage-menu__list {
  @include wide {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .subpage-menu--2columns & {
    @include wide {
      max-width: 815px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
.subpage-menu__list__block {
  background: #666;
  position: relative;
  @include sp {
    height: sp-vw(350);
    box-shadow: sp-vw(40) sp-vw(40) sp-vw(50) rgba(0, 0, 0, 0.35);
    margin-bottom: sp-vw(32);
  }
  @include wide {
    width: calc((100% - 48px) / 3);
    padding-top: calc((100% - 48px) / 3);
    box-shadow: 27px 35px 45px 0px rgba(0, 0, 0, 0.3);
  }
  &:last-child {
    @include sp {
      margin-bottom: 0;
    }
  }
  .subpage-menu--stairs & {
    &:nth-child(2) {
      @include wide {
        margin-top: 30px;
      }
    }
    &:nth-child(3) {
      @include wide {
        margin-top: 60px;
      }
    }
  }
  .subpage-menu--2columns & {
    @include wide {
      width: calc((100% - 24px) / 2);
      padding-top: calc((57.424% - 24px) / 2);
    }
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
  }
}
.subpage-menu__list__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include wide {
    transition: .4s;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position:center center;
  }
}
a:hover .subpage-menu__list__image {
  @include wide {
    transform: scale(1.06)
  }
}
.subpage-menu__list__heading {
  margin-top: sp-vw(-14);
  position: relative;
  z-index: 2;
  @include wide {
    margin-top: -10px;
  }
  &:before {
    content: '';
    display: block;
    width: sp-vw(56);
    height: sp-vw(4);
    background: $bg-color1;
    opacity: .6;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: sp-vw(26);
    @include wide {
      width: 32px;
      height: 2px;
      margin-bottom: 15px;
    }
  }
  em {
    color: $bg-color1;
    font-size: 2.2rem;
    @include wide {
      font-size: 2.8rem;
    }
  }
  span {
    color: $bg-color1;
    font-size: 1.2rem;
    @include wide {
      font-size: 1.2rem;
    }
  }
}