.select-menu {
  display: flex;
  background: $bg-color2;
  @include sp {
    padding:sp-vw(34) $side-padding-sp sp-vw(62);
    margin-right:-$side-padding-sp;
    margin-left:-$side-padding-sp;
  }
  @include wide {
    padding:30px 35px;
  }
}
.select-menu__item {
  @include sp {
    width: 100%;
  }
  @include wide {
    display: flex;
    align-items: center;
  }
  &:not(:last-child) {
    margin-right: sp-vw(22);
    @include wide {
      margin-right: 40px;
    }
  }
  &--wide {
    @include sp {
      width: sp-vw(373);
    }
  }
}
.select-menu__title {
  color:$main-color3;
  font-weight: 600;
  @include sp {
    font-size: 1.7rem;
    letter-spacing: .07em;
  }
  @include wide {
    margin-right: 20px;
  }
}
.select-menu__list {
  position: relative;
  height: sp-vw(100);
  @include wide {
    width: 207px;
    height: 50px;
    border: 1px solid $border-color3;
  }
  &:after {
    content: '';
    display: block;
    border: solid $text-color2;
    border-width:0 1px 1px 0;
    transform: rotate(45deg);
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: sp-vw(34);
    width: sp-vw(16);
    height: sp-vw(16);
    margin-top: sp-vw(-14);
    @include wide {
      width: 8px;
      height: 8px;
      right: 16px;
      margin-top: -4px;
    }
  }
  select {
    background: #fff;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.1em;
    width: 100%;
    height: 100%;
    padding:0 sp-vw(32);
    @include wide {
      padding:0 16px;
    }
  }
}