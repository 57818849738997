.global-button {
	display: flex;
	align-items: center;
	width: sp-vw(454);
	height: sp-vw(104);
	color: #fff;
	@include main_gradient;
	font-size: 1.3rem;
	padding-left: sp-vw(45);
	position: relative;
	@include wide {
		width: 257px;
		height: 57px;
		font-size: 1.4rem;
		padding-left: 25px;
	}
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: '';
		background: $main-color3;
		transform-origin: right top;
		transform: scale(0, 1);
		transition: transform .32s;	
	}
	&[href]:hover ,
	&[type="submit"]:hover ,
	a:hover & {
		&:before {
			@include wide {
				transform-origin: left top;
				transform: scale(1, 1);
			}
		}
	}
	&:after {
		content: '';
		display: block;
		background: url("/assets/img/common/icon_arrow_white.svg") center center no-repeat;
		background-size: contain;
		position: absolute;
		z-index: 2;
		right: sp-vw(24);
		top: 50%;
		width: sp-vw(43);
		height: sp-vw(12);
		margin-top: sp-vw(-7);
		@include wide {
			width: 22px;
			height: 6px;
			margin-top: -4px;
			right: 20px;
			transition: transform .32s;	
		}
	}
	&[href]:hover ,
	&[type="submit"]:hover ,
	a:hover & {
		&:after {
			@include wide {
				transform: translateX(3px);
			}
		}
	}
	&--prev {
		&:after {
			right: auto;
			left: sp-vw(24);
			transform: scaleX(-1);
			@include wide {
				left: 20px;
			}
		}
		&[href]:hover ,
		&[type="submit"]:hover {
			&:after {
				@include wide {
					transform:scaleX(-1) translateX(3px);
				}
			}
		}
	}
	&--border {
		background: none;
		color:$main-color3;
		border:1px solid $main-color3;
		color:$main-color3;
		&:after {
			background: url("/assets/img/common/icon_arrow_navy.svg") center center no-repeat;
		}
		&:hover {
			@include wide {
				color: $bg-color1;
				&:after {
					background-image: url("/assets/img/common/icon_arrow_white.svg");
				}
			}
		}
	}
	&--center {
		justify-content: center;
		padding-left: 0;
		padding-right: 0;
		@include wide {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&--disabled {
		background: #bbb;
		pointer-events: none;
	}
}
.global-button__inner {
	position: relative;
	z-index: 2;
}

.plain-button {
	color: $main-color3;
	background: none;
	display: inline-block;
	position: relative;
	transition: transform .3s;

	&:hover {
		.plain-button::after{
			transform: translateX(3px);
		}
	}

	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		bottom: sp-vw(-6);
		left: 0;
		width: 100%;
		height: 2px;
		@include main_gradient;
		transition: width .3s;

		@include wide {
			bottom: -4px;
		}
	}

	&:after {
		content: "";
		display: inline-block;
		background: url("/assets/img/common/icon_arrow_navy.svg") center center no-repeat;
		background-size: contain;
		width: sp-vw(42);
		height: sp-vw(10);
		margin-left: sp-vw(6);
		margin-bottom: sp-vw(6);
		transition: transform .3s;
		
		@include wide {
			margin-left: 3px;
			margin-bottom: 3px;
			width: 21px;
			height: 5px;

		}
	}
}
