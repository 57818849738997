.footer {
	background: $global-color2;
	color:$bg-color1;
	position: relative;
	overflow: hidden;
	padding: sp-vw(96) 0 sp-vw(55);
	@include wide {
		padding: 70px 0 60px;
	}
	a {
		color:inherit;
		@include wide {
			transition:opacity .3s;
		}
		&:hover {
			@include wide {
				opacity: .7;
			}
		}
	}
}
.footer__pagetop {
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	@include sp {
		top: sp-vw(234);
		right: sp-vw(-15);
		width: sp-vw(120);
		height: sp-vw(260);
	}
	@include wide {
		width: 70px;
		height: 130px;
		top: 43px;
		right: 0;
		cursor: pointer;
	}
	&:before {
		content: '';
		display: block;
		background: url("/assets/img/common/icon_arrow_long_white.svg") no-repeat;
		background-size: 100% 100%;
		transform: rotateZ(-90deg) scaleY(-1);
		transform-origin: top left;
		position: absolute;
		top: sp-vw(96);
		left: 50%;
		width: sp-vw(94);
		height: sp-vw(12);
		margin-left: sp-vw(8);
		@include wide {
			width: 48px;
			height: 6px;
			top: 48px;
			margin-left: 4px;
			transition: transform .2s;
		}
	}
	&:hover  {
		&:before {
			@include wide {
				transform: rotateZ(-90deg) scale(0.9,-1);
			}
		}
	}
	span {
		display: block;
		writing-mode: vertical-rl;
		font-size: 1.3rem;
	}
}
.footer__head {
	@include wide {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
.footer__logo {
	a {
		display: block;
		@include sp {
			margin-right: auto;
			margin-left: auto;
			width: sp-vw(370);
		}
		@include wide {
			width: 228px;
			&:hover {
				opacity: 1;
			}
		}
	}
}
.footer__content {
	@include wide {
		display: flex;
		justify-content: space-between;
	}
}
.footer__navi {
	@include wide {
	}
}
.footer__access {
	display: flex;
	align-items: center;
	flex-wrap:wrap;
	@include sp {
		justify-content: center;
		margin-top: sp-vw(55);
	}
}
.footer__address {
	font-size: 1.3rem;
	width: 100%;
	@include sp {
		letter-spacing: 0.1em;
		margin-bottom: sp-vw(35);
		text-align: center;
	}
	@include wide {
		margin-bottom: 12px;
	}
	strong {
		font-weight: 600;
		font-size: 1.4rem;
		display: block;
		@include sp {
			margin-bottom: sp-vw(8);
		}
		@include wide {
			margin-right: 1em;
			@media (max-width: 1100px) {
				display: block;
			}
		}
	}
}
.footer__fb {
	width: sp-vw(60);
	margin-left: sp-vw(30);
	@include wide {
		width: 30px;
		margin-left: 20px;
	}
	a {
		display: block;
	}
}
.footer__map {
	a {
		font-size: 1.3rem;
		font-weight: 600;
		padding: sp-vw(10) 0;
		display: flex;
		align-items: center;
		@include wide {
			padding:10px 0;
		}
		&:after {
			content: '';
			display: block;
			width: sp-vw(25);
			height: sp-vw(32);
			background: url("/assets/img/common/icon_map_white.svg") center center no-repeat;
			background-size: contain;
			margin-left: sp-vw(10);
			@include wide {
				width: 13px;
				height: 16px;
				margin-left: 5px;
			}
		}
	}
	span {
		display: inline-block;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: $bg-color1;
		}
	}
}
.footer__navi {
	@include sp {
		margin-top: sp-vw(75);
		display: flex;
		flex-direction: column;
		gap: sp-vw(70);
	}
	@include wide {
		gap:18px 48px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr max-content max-content;
		max-width: 564px;
	}
}
.footer__navi__block {
	@include wide {
		&:nth-child(1) {
			grid-area: 1 / 1 / 4 / 2;
		}
		&:nth-child(2) {
			grid-area: 1 / 2 / 2 / 3;
		}
		&:nth-child(3) {
			grid-area: 2 / 2 / 3 / 3;
		}
		&:nth-child(4) {
			grid-area: 3 / 2 / 4 / 3;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.footer__navi__link {
	border-bottom: 1px solid #525972;
	position: relative;
	margin-bottom: sp-vw(22);
	@include wide {
		margin-bottom: 12px;
	}
	&:only-child {
		margin-bottom: 0;
	}
	a {
		display: block;
		padding-bottom: sp-vw(12);
		font-weight: 600;
		@include wide {
			padding-bottom: 10px;
		}
	}
	&::after {
		content: '';
		display: block;
		background: url("/assets/img/common/icon_arrow_white.svg") center center no-repeat;
		background-size: contain;
		position: absolute;
		z-index: 2;
		right: sp-vw(24);
		top: 50%;
		width: sp-vw(36);
		height: sp-vw(10);
		margin-top: sp-vw(-12);
		@include wide {
			width: 18px;
			height: 5px;
			margin-top: -4px;
			right: 12px;
			transition: transform .32s;	
		}
	}
	&:hover::after {
		@include wide {
			transform: translateX(3px);
		}
	}
}
.footer__navi__second-link {
	margin-top: sp-vw(18);
	font-size: 1.4rem;
	@include wide {
		margin-top: 8px;
	}
	a {
		display: block;
	}
}
.footer__navi__child-menu {
	display: flex;
	flex-wrap: wrap;
	margin: sp-vw(10) sp-vw(60) sp-vw(25) sp-vw(35);
	gap:sp-vw(5) 0;
	@include wide {
		margin: 6px 0 15px 16px;
		gap:2px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.footer__navi__child-menu__item {
	a {
		color:#898B93;
		font-size: 1.4rem;
		font-weight: 400;
		letter-spacing: 0.04em;
		&::after {
			content: '/';
			padding-right: 0.4em;
			padding-left: 0.4em;
		}
	}
	&:last-child ,
	&:nth-child(3) {
		a {
			&::after {
				content: none;
			}
		}
	}
}
.footer__sub-content {
	@include sp {
		text-align: center;
		margin-top: sp-vw(100);
	}
	@include wide {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows:1fr max-content;
		align-items: flex-end;
		margin-top: 48px;
	}
}
.footer__banner {
	display: block;
	@include sp {
		margin-bottom: sp-vw(15);
	}
	@include wide {
		max-width: 396px;
		grid-area: 1 / 2 / 3 / 3;
		margin-left: auto;
	}
}
.footer__small-link {
	@include wide {
		grid-area: 1 / 1 / 2 / 2;
	}
	a {
		font-size: 1.2rem;
		display: inline-block;
		color:#898B94;
	}
}
.footer__copyright {
	@include wide {
		grid-area: 2 / 1 / 3 / 2;
	}
	small {
		font-size: 1.2rem;
		letter-spacing: 0.05em;
	}
}