.inview-fadein {
	opacity: 0;
	&.is-view {
		transition: opacity 0.8s;
		opacity: 1;
	}
}
.inview-fadeup {
	opacity: 0;
	transform:translateY(10px);
	@include wide {
		transform:translateY(20px);
	}
	&.is-view {
		transition: opacity 0.8s,transform 0.8s;
		opacity: 1;
		transform:translateY(0);
	}
}
.inview-fadeleft {
	opacity: 0;
	transform:translateX(-10px);
	@include wide {
		transform:translateX(-20px);
	}
	&.is-view {
		transition: opacity 0.8s,transform 0.8s;
		opacity: 1;
		transform:translateY(0);
	}
}
.inview-faderight {
	opacity: 0;
	transform:translateX(10px);
	@include wide {
		transform:translateX(20px);
	}
	&.is-view {
		transition: opacity 0.8s,transform 0.8s;
		opacity: 1;
		transform:translateY(0);
	}
}