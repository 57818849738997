.section-heading {
	text-align: center;
	em {
		display: block;
		font-size: 2.6rem;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0.08em;
		max-height: 100%; // Android Fontsize BugFix
		color:$main-color3;
		@include wide {
			font-size: 3.6rem;
		}
		small {
			@include sp {
				font-size: 2.3rem;
			}
			@include wide {
				font-size: inherit;
			}
		}
	}
	span {
		display: block;
		font-weight: 600;
		font-size: 1.2rem;
		color:$main-color4;
		line-height: 1.3;
		letter-spacing: 0.08em;
		@include sp {
			margin-top: sp-vw(12);
		}
		@include wide {
			letter-spacing: 0.05em;
			font-size: 1.4rem;
			margin-top: 10px;
		}
	}
	&--flex {
		display: flex;
		align-items: center;
		gap:0 sp-vw(30);
		@include wide {
			gap:0 25px;
		}
	}
	&--pc-small {
		em {
			@include wide {
				font-size: 3.2rem;
			}
		}
	}
}

.text-heading {
	text-align: center;
	color:$main-color3;
	font-weight: 600;
	font-size: 2.0rem;
	line-height: 1.5;
	@include wide {
		font-size: 2.7rem;
		letter-spacing: 0.08em;
	}
}

.sub-heading {
	background: $bg-color3;
	border-left:sp-vw(12) solid $main-color3;
	font-weight: 600;
	font-size: 1.8rem;
	padding: sp-vw(12) sp-vw(20);
	@include wide {
		border-left-width: 6px;
		font-size: 2rem;
		padding:5px 14px;
	}
}