.form-box {
	input,textarea {
		background: $bg-color1;
		border:$border-color2 1px solid;
		width: 100%;
	}
	input:not([type="radio"]):not([type="checkbox"]),
	textarea {
		height: sp-vw(106);
		padding:sp-vw(10) sp-vw(26);
		@include wide {
			height: 57px;
			padding:10px 20px;
		}
	}
	textarea {
		height: sp-vw(300);
		@include wide {
			height: 175px;
		}
	}
}
.form-box__block.is-error {
	input,textarea {
		border-color:$caution-color1;
		background: $caution-color2;
	}
}
.form-box__lead {
	text-align: center;
	margin-bottom: sp-vw(40);
	@include wide {
		margin-bottom: 44px;
		font-size: 1.7rem;
	}
}
.form-box__body {
	background: $bg-color4;
	padding: sp-vw(46) $side-padding-sp sp-vw(100);
	@include wide {
		max-width: 816px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 90px;
		padding: 50px 0 65px;
	}
}
.form-box__title {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 1.5rem;
	@include wide {
		font-size: 1.6rem;
	}
}
.form-box__require {
	background:$caution-color1;
	color: $bg-color1;
	font-size: 1.1rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: sp-vw(73);
	height: sp-vw(33);
	@include wide {
		width: 36px;
		height: 16px;
	}
	&:last-child {
		margin-left: sp-vw(13);
		@include wide {
			margin-left: 10px;
		}
	}
}
.form-box__block {
	@include wide {
		width: 649px;
		margin-right: auto;
		margin-left: auto;
	}
	&+.form-box__block {
		margin-top: sp-vw(28);
		@include wide {
			margin-top: 20px;
		}
	}
}
.form-box__field {
	margin-top: sp-vw(12);
	@include wide {
		margin-top: 8px;
	}
}
.form-box__error {
	margin-top: sp-vw(14);
	@include wide {
		margin-top: 8px;
	}
}
.form-box__error__text {
	color:$caution-color1;
	font-size: 1.3rem;
	font-weight: 500;
	letter-spacing: 0.09em;
}
.form-box__confirm {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: sp-vw(70);
	@include wide {
		width: 649px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 45px;
	}
	tr {
		@include sp {
			display: block;
			&:not(:last-child) {
				border-bottom:1px solid $border-color2;
				padding-bottom: sp-vw(26);
				margin-bottom: sp-vw(26);
			}
		}
	}
	th,td {
		vertical-align: top;
		@include sp {
			display: block;
		}
		@include wide {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	th {
		@include wide {
			white-space: nowrap;
			padding-right: 66px;
			width: 203px;
		}
	}
	td {
		@include sp {
			padding-top: sp-vw(8);
			font-size: 1.4rem;
		}
		@include wide {
			border-left:2px solid $border-color2;
			padding-left: 66px;
		}
	}
}
.form-box__confirm__heading {
	position: relative;
	.form-box__require {
		@include sp {
			margin-right: sp-vw(16);
		}
		@include wide {
			position: absolute;
			left: 0;
			top: 5px;
		}
	}
}
.form-box__agree {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: sp-vw(50);
	padding:sp-vw(20);
	@include wide {
		padding: 20px;
		margin-top: 30px;
		cursor: pointer;
	}
	input {
		width: sp-vw(44);
		height: sp-vw(44);
		@include wide {
			width: 22px;
			height: 22px;
		}
	}
	p {
		font-size: 1.4rem;
		position: relative;
		padding-left: 0.6em;
		@include wide {
			font-size: 1.5rem;
		}
		&:before {
			content: '';
			display: block;
			width: sp-vw(24);
			height: sp-vw(24);
			background: $main-color2;
			position: absolute;
			left: sp-vw(-34);
			top: sp-vw(10);
			opacity: 0;
			@include wide {
				width: 12px;
				height: 12px;
				top: 6px;
				left: -17px;
			}
		}
	}
	input:checked ~ p {
		&:before {
			opacity: 1;
		}
	}
	a {
		color:$main-color3;
		text-decoration: underline;
	}
}
.form-box__submit {
	margin-top: sp-vw(34);
	@include wide {
		margin-top: 14px;
	}
	.global-button {
		margin-right: auto;
		margin-left: auto;
	}
}
.form-box__submit__item {
	&+& {
		margin-top:sp-vw(30);
		@include wide {
			margin-top: 15px;
		}
	}
}
.form-box__send-text {
	@include wide {
		text-align: center;
		margin-bottom: 38px;
	}
}