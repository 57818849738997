.related-links {
  padding: sp-vw(100) 0 sp-vw(120);
  @include wide {
    padding: 97px 0 100px;
  }
}
.related-links__menu {
  display: flex;
  @include sp {
    flex-direction: column;
    gap:sp-vw(40)
  }
  @include wide {
    gap:24px;
  }
}
.related-links__heading {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: sp-vw(48);
  @include wide {
    font-size: 2.7rem;
    margin-bottom: 40px;
  }
}
.related-links__menu__image {
  margin-bottom: sp-vw(20);
  @include wide {
    margin-bottom: 10px;
    overflow: hidden;
    img {
      transition: $transition-base;
    }
  }
  a:hover & {
    img {
      @include wide {
        transform: scale(1.04,1.04);
      }
    }
  }
}
.related-links__menu__title {
  font-size: 1.6rem;
  font-weight: 600;
  color:#002D83;
  display: flex;
	&:after {
		content: '';
		display: block;
		background: url("/assets/img/common/icon_arrow_navy.svg") center center no-repeat;
		background-size: contain;
		width: sp-vw(36);
		height: sp-vw(12);
		margin-top: sp-vw(18);
    margin-left: sp-vw(14);
		@include wide {
			width: 18px;
			height: 6px;
			margin-top: 9px;
      margin-left: 8px;
			transition: transform .32s;	
		}
	}
  a:hover & {
    &:after {
      @include wide {
        transform:translateX(3px);
      }
    }
  }
}