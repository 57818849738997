.text-header {
  margin-bottom: sp-vw(26);
  @include wide {
    margin-bottom: 30px;
  }
  .inner {
    @include sp {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .breadcrumb {
    margin-top: sp-vw(20);
    @include wide {
      margin-top: 16px;
    }
  }
}
.text-header__content{
  @include main_gradient;
  color: $bg-color1;
  display: flex;
  align-items: center;
  @include sp {
    margin-left:$side-padding-sp;
    height: sp-vw(250);
    padding-left: sp-vw(54);
  }
  @include wide {
    width: calc(50% + 600px);
    max-width:calc(100% - #{$side-padding-pc});
    height: 165px;
    padding-left: 55px;
    margin-right: 0;
    margin-left: auto;
  }
}
.text-header__title {
  &.section-heading {
    text-align: left;
    em,span {
      color:inherit;
    }
    em {
      font-size: 2.8rem;
      @include wide {
        font-size: 4.0rem;
      }
    }
    span {
      font-size: 1.4rem;
      @include wide {
        font-size: 1.8rem;
      }
    }
  }
}