.contact {
	background: url("/assets/img/common/bg_contact_sp.jpg") no-repeat center center;
	background-size: cover;
	padding:sp-vw(90) 0;
	@include wide {
		padding:92px 0;
		background-image: url("/assets/img/common/bg_contact_pc.jpg");
	}
}
.contact__box {
	background: rgba($bg-color1,.7);
	padding:sp-vw(88) 0 sp-vw(78);
	box-shadow: 20px 26px 32px 0px rgba(0, 0, 0, 0.2);
	@include wide {
		display: flex;
		justify-content: center;
		box-shadow: 20px 50px 15px rgba(0, 0, 0, 0.16);
		padding:58px 0;
		max-width: 816px;
		gap:54px;
		margin-right: auto;
		margin-left: auto;
	}
}
.contact__heading {
	@include wide {
		margin-top: 9px;
		.section-heading {
			text-align: left;
		}
	}
}
.contact__outline {
	@include wide {
		padding-top: 12px;
		padding-bottom: 10px;
		padding-left: 56px;
		border-left: 1px solid $border-color3;
	}
}
.contact__tel {
	@include sp {
		margin-top: sp-vw(64);
		text-align: center;
	}
	em {
		font-weight: 600;
		display: block;
		margin-left: -0.1em;
		font-size: 2.8rem;
		line-height: 1;
		letter-spacing: 0.03em;
		@include wide {
			font-size: 3.7rem;
			letter-spacing: 0.06em;
		}
	}
	small {
		color:$text-color2;
		display: block;
		font-size: 1.4rem;
		letter-spacing: 0.03em;
		margin-top: sp-vw(14);
		max-height: 100%; // Android Fontsize BugFix
		@include wide {
			font-size: 1.5rem;
			margin-top:8px;
			letter-spacing: 0.07em;
		}
	}
}
.contact__button {
	margin-top: sp-vw(36);
	@include wide {
		margin-top: 26px;
	}
	.global-button {
		@include sp {
			margin-right: auto;
			margin-left: auto;
		}
	}
}