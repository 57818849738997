html {
  font-family:$font-base-family;
  width: 100%;
  font-size: 62.5%;
  @include sp {
    font-size: calc( 1000vw / #{$sp-viewport} );
  }
}
body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: .1em;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  color: $text-color1;
  background: $bg-color1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  font-feature-settings: "palt" 1;
  position: relative;
  @include wide {
    min-width:$min-width;
  }
  // &.is-navi-open {
  //   overflow: hidden;
  //   height: 100%;
  // }
}
a {
  outline: none;
  color: $text-color1;
  text-decoration: none;
  &[data-anchor] {
    cursor: pointer;
  }
}
a:focus,
*:focus {
  outline:none;
}
h1,h2,h3,h4,h5,h6,strong,th {
  font-weight: normal;
}
em {
  font-style: normal;
}
select,input,textarea,button {
  font-family: inherit;
  border-radius: 0;
  -webkit-appearance: none; 
  display: block;
}
select,button {
 cursor: pointer;
}
input[type=number] {
  -moz-appearance:textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type=search]::-webkit-search-cancel-button {
   -webkit-appearance: none;
}
input,textarea,select { 
  &::-webkit-input-placeholder {
    font-size: 1.5rem;
    font-weight: 400;
    color: #b9b9b9;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 1.6rem;
    }
  }
  &:-moz-placeholder {
    font-size: 1.5rem;
    font-weight: 400;
    color: #b9b9b9;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 1.6rem;
    }
  }
  &::-moz-placeholder {
    font-size: 1.5rem;
    font-weight: 400;
    color: #b9b9b9;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 1.6rem;
    }
  }
  &:-ms-input-placeholder {
    font-size: 1.5rem;
    font-weight: 400;
    color: #b9b9b9;
    opacity:1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 1.6rem;
    }
  }  
}
main {
  min-height: 0%; // body flex hack for IE
  margin-bottom: auto;
  overflow: hidden;
  @include sp {
    margin-top: $header-height-sp;
  }
  @include wide {
    margin-top: $header-height-pc;
  }
}
ul {
  list-style-type: none;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

