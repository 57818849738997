.breadcrumb {
	overflow: auto;
	text-align: right;
}
.breadcrumb__list {
	display: inline-flex;
}
.breadcrumb__item {
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	white-space: nowrap;
	padding-bottom: sp-vw(22);
	@include wide {
		font-size: 1.4rem;
		padding-bottom: 18px;
	}
	@include sp {
		&:first-child {
			padding-left:$side-padding-sp;
		}
		&:last-child {
			padding-right:$side-padding-sp;
		}
	}
	&:not(:last-child):after {
		content: '/';
		font-size: 1.5rem;
		color:$main-color4;
		padding:0 0.25em;
		@include wide {
			font-size: 1.6rem;
		}
	}
	a {
		color:$main-color3;
		display: block;
	}
}