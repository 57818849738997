.bg-line {
	position: fixed;
	width: 50%;
	height: 100%;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	border:solid #f1f1f1;
	border-width: 0 1px 0 1px;
	z-index: -1;
	&:before {
		content: '';
		display: block;
		width: 1px;
		height: 100%;
		background: #f1f1f1;
		margin: 0 auto;
	}
}