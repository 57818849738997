.simple-section {
	padding-bottom: sp-vw(100);
	@include wide {
		padding-bottom: 80px;
	}
	.page-title + & {
		padding-top: sp-vw(30);
		@include wide {
			padding-top: 30px;
		}
	}
	.sub-heading {
		margin-bottom: sp-vw(30);
		@include wide {
			margin-bottom: 22px;
		}
	}
}
.simple-section__block {
	&+& {
		margin-top: sp-vw(60);
		@include wide {
			margin-top: 40px;
		}
	}
}
.simple-section__text {
	@include sp {
		font-size: 1.4rem;
		line-height: 1.76;
		letter-spacing: 0.08em;
	}
	&+& {
		margin-top: 1.2em;
	}
}
.simple-section__button {
	margin-top: sp-vw(60);
	@include wide {
		margin-top: 35px;
	}
	.global-button {
		@include sp {
			margin-right: auto;
			margin-left: auto;
		}
	}
}