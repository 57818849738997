.page-title {
	margin-top: sp-vw(50);
	@include wide {
		margin-top: 26px;
	}
	&--no-image {
		text-align: center;
		@include wide {
			margin-top: 44px;
		}
	}
	.inner {
		@include sp {
			padding-right: 0;
			padding-left: 0;
		}
	}
	.breadcrumb {
		margin-top: sp-vw(20);
		@include wide {
			margin-top: -12px;
		}
	}
	.breadcrumb__item {
		@include sp {
			padding-bottom: sp-vw(28);
		}
	}
}
.page-title__heading {
	@include sp {
		padding-left: $side-padding-sp;
	}
	.page-title--no-image & {
		@include sp {
			margin-top: sp-vw(65);
			margin-bottom: sp-vw(90);
			padding-left: 0;
		}
		@include wide {
			margin-top: 60px;
		}
	}
	em {
		display: block;
		font-size: 3rem;
		font-weight: 600;
		letter-spacing: 0.04em;
		color:$main-color3;
		max-height: 100%; // Android Fontsize BugFix
		@include wide {
			font-size: 4.8rem;
		}
	}
	span {
		display: block;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 1.3;
		color:$main-color4;
		letter-spacing: 0.05em;
		margin-top:sp-vw(5);
		@include wide {
			letter-spacing: 0.05em;
			font-size: 1.8rem;
			margin-top: 5px;
		}
	}

	&--position-adjust {
		text-align: center;
		margin-top: sp-vw(78);
		@include sp {
			padding: 0;
		}

		@include wide {
			margin-top: 58px;
			margin-bottom: 80px;
		}
	}
}
.page-title__image {
	box-shadow: 12px 15px 20px 0px rgba(0, 0, 0, 0.1);
	@include wide {
		height: 314px;
		box-shadow: 33px 44px 55px 0px rgba(0, 0, 0, 0.1);
	}
	&--size-adjust {
		width: 83.733%;
		margin: sp-vw(102) auto 0;

		@include wide {
			height: 550px;
			max-width: 984px;
			margin: 0 auto;
		}
	}

	&--frame {
		@include wide {
			height: 493px;
			max-width: 1154px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	img {
		width: 100%;
		height: 100%;
		object-fit:cover;
		object-postion:center center;
	}
	&--no-shadow {
		box-shadow: none;
	}
}
.page-title__text-box {
	background: #fff;
	margin:sp-vw(-120) sp-vw(60) 0;
	box-shadow: sp-vw(20) sp-vw(50) sp-vw(50) rgba(0, 0, 0, 0.15);
	position: relative;
	z-index: 1;
	padding: sp-vw(70) sp-vw(60) sp-vw(68);
	@include wide {
		width: calc(100% - #{$side-padding-pc} * 2);
		max-width: 984px;
		margin: -82px auto 0 auto;
		padding: 48px 84px 44px;
		box-shadow: 20px 50px 50px rgba(0, 0, 0, 0.15);
	}
}
.page-title__sub-heading {
	font-weight: 600;
	font-size: 2rem;
	line-height: 1.5;
	letter-spacing: 0.12em;
	color: #012F7E;
	margin-bottom: sp-vw(20);
	@include wide {
		font-size: 2.8rem;
		text-align: center;
		margin-bottom: 14px;
	}
}
.page-title__description {
	font-size: 1.4rem;
	line-height: 2;
	@include wide {
		font-size: 1.5rem;
		letter-spacing: 0.1em;
	}
	span {
		display: inline-block;
		margin-top: sp-vw(26);
		@include wide {
			margin-top: 0;
		}
	}
}
