.header {
	width: 100%;
	height: $header-height-sp;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 899;
	display: flex;
	align-items: center;
	padding-left:$side-padding-sp;
	@include wide {
		padding-left: 28px;
	}
	@include wide {
		height: $header-height-pc;
		min-width: $min-width;
	}
	&:after {
		content: '';
		display: block;
		background: rgba($bg-color1,0.8);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		transform: translateY(-100%);
		transition: transform .3s;
	}
	.is-header-bg & {
		&:after {
			transform: translateY(0);
			box-shadow: 16px 20px 25px 0px rgba(#666, 0.1);
		}
	}
	.is-navi-open & {
		&:after {
			@include sp {
				transform: translateY(0);
				box-shadow: 16px 20px 25px 0px rgba(#666, 0.1);
			}
		}
	}
}
.header__overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000,0.5);
	z-index: 0;
	opacity: 0;
	pointer-events: none;
	transition: .2s;
	.is-navi-open & {
		@include wide {
			opacity: 1;
			pointer-events: auto;
		}
	}
}
.header__logo {
	width: sp-vw(310);
	margin-top: sp-vw(12);
	@include wide {
		width: 166px;
		margin-top: 4px;
	}
}
.header__quickmenu {
	@include sp {
		display: none;
	}
	@include wide {
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 60px;
	}
}
.header__quickmenu__item {
	@include wide {
		height: 100%;
		position: relative;
	}
	span {
		position: relative;
		@include wide {
			display: flex;
			align-items: center;
		}
		&::before {
			@include wide {
				content: '';
				display: block;
				width: 24px;
				height: 2px;
				background: $main-color2;
				position: absolute;
				bottom: -8px;
				left: 0;
				right: 0;
				margin: auto;
				transform: scale(0);
				transition: transform .35s;
				transform-origin:center right;
			}
		}
	}
	&--has-child {
		span {
			&::after {
				@include wide {
					content: '';
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 6px 4px 0 4px;
					border-color: $text-color1 transparent transparent transparent;	
					margin-left: 5px;
					margin-top: 2px;
				}
			}
		}
	}
}
.header__quickmenu__title {
	@include wide {
		display: flex;
		align-items: center;
		height: 100%;
		padding:0 17px;
		font-weight: 500;
		cursor: pointer;
		&:hover {
			color:$main-color3;
			span {
				&::before {
					@include wide {
						transform: scale(1);
						transform-origin:center left;
					}
				}
				&::after {
					@include wide {
						border-top-color:$main-color2;
					}
				}
			}
		}
	}
}
.header__quickmenu__second-menu {
	@include wide {
		position: fixed;
		left: 0;
		top: $header-height-pc;
		background: rgba(#001741,.9);
		width: 100%;
		color: #fff;
		padding: 32px 0;
		min-width: $min-width;
		transition: $transition-base;
		.header__quickmenu__item:not(.is-second-open) & {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}
}
.header__quickmenu__second-menu__list {
	@include wide {
		margin-right: auto;
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		gap:16px 24px;
	}
}
.header__quickmenu__second-menu__item {
	@include wide {
		width: calc((100% - 24px * 2) / 3);
		border-bottom: 1px solid #505974;
	}
}
.header__quickmenu__second-menu__link {
	@include wide {
		display: flex;
		align-items: flex-end;
		gap:12px;
		position: relative;
		height: 64px;
	}
	&::after {
		@include wide {
			content: '';
			display: block;
			background: url("/assets/img/common/icon_arrow_white.svg") no-repeat;
			background-size: contain;
			position: absolute;
			width: 18px;
			height: 6px;
			top: 50%;
			right: 15px;
			margin-top: -4px;
			transition: transform .2s;
		}
	}
	&:hover {
		&::after {
			@include wide {
				transform: translateX(6px);
			}
		}
	}
}
.header__quickmenu__second-menu__thumbnail {
	@include wide {
		width: 100px;
		margin-bottom: -1px;
		overflow: hidden;
		img {
			transition: $transition-base;
		}
		a:hover & {
			img {
				transform: scale(1.1,1.1);
			}
		}
	}
}
.header__quickmenu__second-menu__title {
	@include wide {
		color: #fff;
		font-size: 1.4rem;
		padding-right: 35px;
		height: 100%;
		display: flex;
		align-items: center;
	}
}
.header__contact {
	margin-right: 0;
	margin-left: auto;
	height: 100%;
	background: $global-color4;
	width: sp-vw(120);
	@include wide {
		width: 120px;
		transition: background $transition-base;
		&:hover {
			background: #012F7E;
		}
	}
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		&::before {
			content: '';
			display: block;
			width: sp-vw(45);
			height: sp-vw(33);
			background: $bg-color1;
			mask:url("/assets/img/common/icon_mail_gray.svg") no-repeat center center / contain;
			@include wide {
				width: 18px;
				height: 13px;
			}
		}
	}
	em {
		@include sp {
			display: none;
		}
		@include wide {
			color:#FFF;
			font-size: 1.2rem;
			letter-spacing: 0.02em;
			margin-left: 8px;
		}
	}
}
.header__humberger {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction:column;
	background: $global-color1;
	width: sp-vw(120);
	height: 100%;
	position: relative;
	z-index: 5;
	overflow: hidden;
	@include wide {
		width: 74px;
		cursor: pointer;
		transition: .3s;
		display: none;
	}
	span {
		display: block;
		height: 2px;
		background: $bg-color1;
		width: sp-vw(50);
		transition: transform .3s;
		position: relative;
		z-index: 1;
		@include wide {
			width: 30px;
		}
		&:nth-child(2) {
			margin:sp-vw(16) 0;
			@include wide {
				margin-top: 8px;
				margin-bottom: 8px;
			}
		}
	}
	body:not(.is-navi-open) & {
		span {
			height: 1px;
			&:nth-child(3) {
				@include wide {
					transform-origin:center left;
				}
			}
		}
		&:hover span {
			&:nth-child(3) {
				@include wide {
					transform: scaleX(.7);
				}
			}
		}
	}
	.is-navi-open & {
		span {
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				display: none;
			}
			&:nth-child(3) {
				transform: rotate(-45deg);
				margin-top: -2px;
			}
		}
	}
}
.header__navi {
	position: fixed;
	width: 100%;
	height: calc(100vh - #{$header-height-sp});
	overflow: auto;
	right: 0;
	top: $header-height-sp;
	background: $global-color1;
	transform: translateX(100%);
	transition: .3s;
	padding:sp-vw(40) $side-padding-sp sp-vw(200);
	z-index: 1;
	@include wide {
		max-width: 443px;
		height: 100vh;
		top: 0;
		padding:40px 60px 70px;
	}
	.is-navi-open & {
		transform: translateX(0);
	}
}
.header__gmenu {
	color: #fff;
	transform: translateX(20px);
	transition: 0.4s 0.25s;
	opacity: 0;
	.is-navi-open & {
		transform: translateX(0);
		opacity: 1;
	}
	a {
		color: #fff;
		@include wide {
			transition: .3s;
			&:hover {
				color:rgba(#FFF,0.7)
			}
		}
	}
}
.header__gmenu__link {
	display: flex;
	align-items: center;
	font-weight: 600;
	border-bottom: 1px solid rgba(#FFF,0.3);
	width: 100%;
	height: sp-vw(116);
	padding-left: sp-vw(16);
	cursor: pointer;
	position: relative;
	font-size: 1.8rem;
	@include wide {
		height: 58px;
		padding:0 0 0 10px;
	}
	.header__gmenu__item:last-child & {
		border-bottom: none;
	}
	&:not([href]) {
		&:before,&:after {
			content: '';
			display: block;
			background: #fff;
			position: absolute;
			right: sp-vw(32);
			top: 50%;
			transition: .4s;
			@include wide {
				right: 20px;
			}
		}
		&:before {
			width: 2px;
			height: calc(#{sp-vw(26)} + 1px);
			margin-top: calc(#{sp-vw(-11)} - 1px);
			@include wide {
				height: 15px;
				margin-top: -7px;
			}
		}
		&:after {
			width: calc(#{sp-vw(26)} + 1px);
			height: 2px;
			margin-right: calc(#{sp-vw(-13)} + 1px);
			@include wide {
				width: 15px;
				margin-right: -6px;
			}
		}
	}
	.header__gmenu__item.is-second-open & {
		&:not([href]) {
			&:before,&:after {
				transform: rotate(90deg);
			}
			&:after {
				opacity: 0;
			}
		}
	}
}
.header__gmenu__second-menu {
	border-bottom: 1px solid rgba(#FFF,0.3);
	transition: .4s;
	padding: sp-vw(28) 0 sp-vw(32) sp-vw(20);
	@include wide {
		padding:16px 0 16px 10px;
	}
	.header__gmenu__item:not(.is-second-open) & {
		opacity: 0;
		height: 0;
		overflow: hidden;
		padding-top: 0;
		padding-bottom: 0;
	}
}
.header__gmenu__second-menu__link {
	font-weight: 600;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 1.7rem;
	padding: sp-vw(12) 0;
	@include wide {
		padding:6px 0;
	}
	&:not([href]) {
		&:after {
			content: '';
			display: block;
			border:  solid #FFF;
			border-width: 0 0 1px 1px;
			transform: rotate(-45deg);
			width: sp-vw(16);
			height: sp-vw(16);
			margin-left: sp-vw(16);
			margin-top: sp-vw(-4);
			@include wide {
				width: 8px;
				height: 8px;
				margin-top: -2px;
				margin-left: 6px;
			}
		}
	}
	.header__gmenu__second-menu__item.is-third-open & {
		&:not([href]) {
			&:after {
				border-width: 1px 1px 0 0;
				margin-top: sp-vw(5);
				@include wide {
					margin-top: 4px;
				}
			}
		}
	}
}
.header__gmenu__third-menu {
	transition: .4s;
	padding: sp-vw(5) 0 sp-vw(16) sp-vw(20);
	@include wide {
		padding:0 0 8px 10px;
	}
	.header__gmenu__second-menu__item:not(.is-third-open) & {
		opacity: 0;
		height: 0;
		overflow: hidden;
		padding-top: 0;
		padding-bottom: 0;
	}
}
.header__gmenu__third-menu__item {
	font-size: 1.5rem;
	letter-spacing: 0.1em;	
	a {
		display: block;
		padding: sp-vw(8) 0;
		@include wide {
			padding:3px 0;
		}
	}
}