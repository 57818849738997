.gallery-link {
  padding: sp-vw(120) 0 sp-vw(20);
  position: relative;
  @include wide {
    padding-top: 100px;
    padding-bottom: 0;
  }
}
// .gallery-link__heading {
//   margin-bottom: sp-vw(55);
//   @include wide {
//     margin-bottom: 36px;
//   }
// }
.gallery-link__box {
  @include wide {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  // .subpage-menu--2columns & {
  //   @include wide {
  //     max-width: 815px;
  //     margin-right: auto;
  //     margin-left: auto;
  //   }
  // }
}

.gallery-link__block {
  // background: #666;
  position: relative;
  width: 100%;

  @include sp {
    height: sp-vw(350);
    box-shadow: sp-vw(40) sp-vw(40) sp-vw(50) rgba(0, 0, 0, 0.35);
    margin-bottom: sp-vw(32);
  }
  @include wide {
    padding-top: calc((100% - 48px) / 3);
    box-shadow: 27px 35px 45px 0px rgba(0, 0, 0, 0.3);
    height: 340px;
  }
  // &:last-child {
  //   @include sp {
  //     margin-bottom: 0;
  //   }
  // }
  // .subpage-menu--stairs & {
  //   &:nth-child(2) {
  //     @include wide {
  //       margin-top: 30px;
  //     }
  //   }
  //   &:nth-child(3) {
  //     @include wide {
  //       margin-top: 60px;
  //     }
  //   }
  // }
  // .subpage-menu--2columns & {
  //   @include wide {
  //     width: calc((100% - 24px) / 2);
  //     padding-top: calc((57.424% - 24px) / 2);
  //   }
  // }
  #flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
  }
  
  &::before {
    content: "";
    display: block;
    width: sp-vw(80);
    height: sp-vw(60);
    border-left: 1px solid $bg-color1;
    border-top: 1px solid $bg-color1;
    opacity: 0.8;
    position: absolute;
    top: sp-vw(30);
    left: sp-vw(30);
    z-index: 1;
    
    @include wide {
      top: 20px;
      left: 20px;
      width: 59px;
      height: 44px;
      
    }
  }
  &::after {
    content: "";
    display: block;
    width: sp-vw(80);
    height: sp-vw(60);
    border-right: 1px solid $bg-color1;
    border-bottom: 1px solid $bg-color1;
    opacity: 0.8;
    position: absolute;
    bottom: sp-vw(30);
    right: sp-vw(30);
    z-index: 1;
    
    @include wide {
      width: 59px;
      height: 44px;
      bottom: 20px;
      right: 20px;
    }
  }
}



.gallery-link__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include wide {
    transition: .4s;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position:center center;
  }
}
a:hover .gallery-link__image {
  @include wide {
    transform: scale(1.06)
  }
}

.gallery-link__heading {
  margin-top: sp-vw(20);
  // margin-bottom: sp-vw(55);
  position: relative;
  z-index: 2;
  // @include wide {
  //   margin-top: 2px;
  //   margin-bottom: 36px;
  // }
  // &:before {
  //   content: '';
  //   display: block;
  //   width: sp-vw(56);
  //   height: sp-vw(4);
  //   background: $bg-color1;
  //   opacity: .6;
  //   margin-right: auto;
  //   margin-left: auto;
  //   margin-bottom: sp-vw(26);
  //   @include wide {
  //     width: 32px;
  //     height: 2px;
  //     margin-bottom: 15px;
  //   }
  // }
  em {
    color: $bg-color1;
    font-size: 2.6rem;
    @include wide {
      font-size: 3.6rem;
    }
  }
  span {
    color: $bg-color1;
    font-size: 1.2rem;
    font-weight: 600;
    @include wide {
      font-size: 1.4rem;
    }
  }

  &::before {
    display: none;
  }

  &:after {
    content: "";
    display: inline-block;
    background: url("/assets/img/common/icon_arrow_white.svg") center center no-repeat;
    background-size: contain;
    position: absolute;
    top: sp-vw(28);
    right: sp-vw(-62);
    width: sp-vw(46);
    height: sp-vw(14);
    transition: transform .3s;
    
    @include wide {
      top: 17px;
      right: -48px;
      width: 28px;
      height: 6px;
    }
  }
}