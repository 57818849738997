.ordered-list {
  counter-reset: ordered 0;
}
.ordered-list__item {
  position: relative;
  display: block;
  padding-left: 1.3em;
  &:not(:first-child) {
    margin-top: 0.3em;
    @include wide {
      margin-top: 0.4em;
    }
  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: counter(ordered) ". ";
    counter-increment: ordered 1;
  }
  .ordered-list {
    margin-top: 0.4em;
    margin-bottom: 0.8em;
    @include wide {
      margin-top: 0.6em;
      margin-bottom: 1em;
    }
  }
  &:last-child {
    .ordered-list {
      margin-bottom: 0;
    }
  }
}
