.single {
  @include sp {
    padding-top: sp-vw(18);
  }
}
.single-head {
  border-bottom: 2px solid $border-color2;
  padding-bottom: sp-vw(36);
  @include wide {
    padding-bottom: 26px;
  }
}
.single-meta {

  @include wide {
    display: flex;
    align-items: center;
  }
  .article-date {
    display: block;
    margin-bottom: sp-vw(16);
    @include wide {
      margin-right: 7px;
      margin-bottom: 0;
    }
  }
  .article-labels {
    display: flex;
    flex-wrap: wrap;
    gap: sp-vw(12) sp-vw(20);
    margin-bottom: sp-vw(26);

    @include wide {
      gap: 10px;
      margin-bottom: 0;
      align-items: center;
    }
  }

  .article-label {
    display: inline-block;
    text-align: center;
    width: auto;
    height: auto;
    min-width: sp-vw(250);
    padding: 0 sp-vw(36);
    line-height: 1.7;
    
    @include wide {
      padding: 0 24px;
      min-width: 125px;
    }
  }
}

.single-title {
  font-weight: 700;
  color:$main-color3;
  letter-spacing: 0.1em;
  line-height: 1.4615;
  font-size: 2.6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  @include wide {
    font-size: 3.2rem;
    letter-spacing: .12em;
    line-height: 1.5;
  }
  .single-meta + & {
    margin-top: sp-vw(15);
    @include wide {
      margin-top: 13px;
    }
  }
}
.single-eyecatch {
  height: sp-vw(390);
  margin-top: sp-vw(88);
  @include wide {
    max-width: 648px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 65px;
    max-height: 405px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.single-body {
  @include wide {
    max-width: 824px;
    margin: 0 auto;
  }

  h2 {
    font-weight: 700;
    color:$main-color3;
    letter-spacing: 0.1em;
    line-height: 1.5;
    font-size: 2.2rem;
    padding: sp-vw(40);
    background-color: $bg-color4;
    margin-top: sp-vw(82);

    @include wide {
      padding: 15px 26px;
      font-size: 2.6rem;
      line-height: 1.6;
      letter-spacing: .125em;
      margin-top: 59px;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 2;
    letter-spacing: .1em;
    margin-top: sp-vw(28);

    @include wide {
      font-size: 1.5rem;
      letter-spacing: .16em;
      margin-top: 11px;
    }
  }

  a {
    display: inline-block;
    padding-bottom: sp-vw(2);
    border-bottom: 1px solid $main-color3;
    color:$main-color3;
    line-height: 1;
    position: relative;

    @include wide {
      padding-bottom: 1px;
    }
  }

  a[target="_blank"] {
    display: inline-block;
    border-bottom: 1px solid $main-color3;
    color:$main-color3;
    line-height: 1;

    @include wide {
      margin-right:22px;
    }

    &::after {
      content: "";
      display: inline-block;
      background-image: url(../img/news/pct_icon.svg);
      background-size: cover;
      width: sp-vw(28);
      height: sp-vw(24);
      position: absolute;
      top: 50%;
      right: sp-vw(-36);
      transform: translateY(-50%);

      @include wide {
        width: 14px;
        height: 12px;
        right: -16px;
      }
    }
  }

  h3 {
    font-weight: 700;
    color:$main-color3;
    letter-spacing: 0.1em;
    line-height: 1.5;
    font-size: 2rem;
    margin-top: sp-vw(90);
    margin-bottom: sp-vw(30);
    padding-left: sp-vw(26);
    position: relative;

    @include wide {
      margin-top: 46px;
      margin-bottom: 16px;
      font-size: 2.2rem;
      padding-left: 18px;
      letter-spacing: .08em;
    }

    &::before {
      content: "";
      display: inline-block;
      background-color: #687B99;
      position: absolute;
      width: sp-vw(8);
      height: 92%;
      top: sp-vw(8);
      left: 0;

      @include wide {
        width: 8px;
        top: 4px;
      }
    }
  }

  h4 {
    font-weight: 700;
    color:$main-color3;
    letter-spacing: .12em;
    line-height: 1.5;
    font-size: 1.8rem;
    margin-top: sp-vw(90);
    margin-bottom: sp-vw(30);
    padding-bottom: sp-vw(10);
    border-bottom: 1px solid $border-color3;

    @include wide {
      margin-top: 45px;
      font-size: 2rem;
      letter-spacing: .13em;
      padding-bottom: 4px;
      margin-bottom: 15px;
    }  
  }

  h5 {
    font-weight: 700;
    color:$main-color3;
    letter-spacing: .12em;
    line-height: 1.5;
    font-size: 1.6rem;
    margin-top: sp-vw(94);
    margin-bottom: sp-vw(26);

    @include wide {
      font-size: 1.8rem;
      margin-top: 47px;
      margin-bottom: 15px;
      letter-spacing: .13em;
    }
  }

  ul {
    list-style: inside;
    font-size: 1.4rem;
    line-height: 2;
    margin-top: sp-vw(62);
    padding-left: sp-vw(36);
    text-indent: sp-vw(-30);
    letter-spacing: .08em;

    @include wide {
      margin-top: 22px;
      padding-left: 19px;
      text-indent: -16px;
      letter-spacing: .16em;
      font-size: 1.5rem;
    }

    li::marker {
      font-size: 1.1rem;

      @include wide {
        font-size: .8rem;
      }

      
    }

    li+li {
      margin-top: sp-vw(20);

      @include wide {
        margin-top: 10px;
      }
    }
  }

  ol {
    list-style: decimal;
    font-size: 1.4rem;
    line-height: 2;
    margin-top: sp-vw(62);
    padding-left: sp-vw(40);
    letter-spacing: .08em;

    @include wide {
      padding-left: 19px;
      font-size: 1.5rem;
      margin-top: 21px;
      letter-spacing: .16em;
    }

    li::marker {
      font-size: 1.6rem;
    }

    li+li {
      margin-top: sp-vw(12);

      @include wide {
        margin-top: 8px;
      }
    }
  }

  blockquote {
    padding: sp-vw(80) sp-vw(60);
    background-color: $bg-color4;
    font-size: 1.4rem;
    line-height: 2;
    margin-top: sp-vw(106);
    letter-spacing: .09em;
    position: relative;

    @include wide {
      padding: 37px 34px 47px;
      font-size: 1.5rem;
      margin-top: 47px;
      letter-spacing: .16em;
    }

    &::before {
      content: "";
      display: inline-block;
      background-image: url("/assets/img/news/pct_quotation01_pc.svg");
      background-size: cover;
      width: sp-vw(80);
      height: sp-vw(60);
      position: absolute;
      top: sp-vw(-14);
      left: sp-vw(40);

      @include wide {
        width: 40px;
        height: 30px;
        top: -10px;
        left: 27px;
      }
    }

    &::after {
      content: "";
      display: inline-block;
      background-image: url("/assets/img/news/pct_quotation02_pc.svg");
      background-size: cover;
      width: sp-vw(80);
      height: sp-vw(60);
      position: absolute;
      bottom: sp-vw(-12);
      right: sp-vw(42);

      @include wide {
        width: 40px;
        height: 30px;
        bottom: -9px;
        right: 27px;
      }
    }   
  }

  cite {
    display: block;
    font-style: normal;
    text-align: right;
    margin-top: sp-vw(42);

    @include wide {
      margin-top: 11px;
      letter-spacing: .12em;
    }
  }

  .size-full {
    margin-top: sp-vw(114);

    @include wide {
      max-width: 647px;
      margin: 50px auto 0;
    }

    img {
      width: 100%;
    }
  }


  .size-large {
    margin-top: sp-vw(60);

    @include wide {
      max-width: 647px;
      margin: 40px auto 0;
    }

    img {
      width: 100%;
    }

  }
  
  figcaption {
    margin-top: sp-vw(20);
    font-size: 1.3rem;
    letter-spacing: .12em;
    line-height: 1.5;

    @include wide {
      margin-top: 10px;
      letter-spacing: .13em;
    }
  }

  .size-medium {
    width: 74.203%;
    margin: 0 auto;
    margin-top: sp-vw(60);
  
    @include wide {
      width: 100%;
      max-width: 647px;
      margin: 40px auto 0;
    }

    img {
      width: 100%;

      @include wide {
        margin-right: auto;
        max-width: 480px;
      }
    }
  }

  .size-thumbnail {
    width: 48.407%;
    margin: 0 auto;
    margin-top: sp-vw(60);

    @include wide {
      width: 100%;
      max-width: 647px;
      margin: 40px auto 0 ;
    }

    img {
      width: 100%;

      @include wide {
        margin-right: auto;
        max-width: 312px;
      }
    }
  }
}


.single-back {
  margin: sp-vw(100) auto sp-vw(120);
  @include wide {
    margin: 71px auto 98px;
  }
}