.article-date {
	color:$text-color2;
	font-weight: 500;
  font-size: 1.6rem;
}
.article-label {
	display: flex;
	align-items: center;
	justify-content: center;
	border:1px solid $border-color1;
	color:$main-color3;
	width: sp-vw(252);
	height: sp-vw(44);
	font-size: 1.2rem;
	@include wide {
		width: 125px;
		height: 22px;
	}
}
.article-title {
	font-weight: 500;
	font-size: 1.4rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.8;  
	@include wide {
		flex:1;
    font-size: 1.5rem;
    line-height: 1.55;
	}
	a:hover & {
		@include wide {
			color:$main-color3;
		}
	}
}



.simple-article__meta {
	display: flex;
	align-items: center;
	@include sp {
		margin-bottom: sp-vw(20);
	}
	@include wide {
		margin-right: 32px;
	}
	.article-date {
		width: sp-vw(195);
		@include wide {
			width: 125px;
		}
	}
}
.simple-article__inner {
	width: 100%;
	display: block;
	padding: sp-vw(22) 0;
	@include wide {
		display: flex;
		padding: 10px 0;
	}
}



.primary-article-list {
  margin-bottom: sp-vw(80);
  @include wide {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 58px;
  }
  .select-menu+& {
    margin-top: sp-vw(34);
    @include wide {
      margin-top: 50px;
    }
  }
  .primary-article {
    @include wide {
      width: calc((100% - 48px) / 3);
      margin-right: 24px;
      margin-top: 46px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(-n+3) {
        margin-top: 0;
      }
    }
  }
}
.primary-article {
  &+& {
    @include sp {
      margin-top: sp-vw(52);
    }
  }
}
.primary-article__inner {
	width: 100%;
	display: block;
}
.primary-article__image {
  overflow: hidden;
  height: sp-vw(393);
  @include wide {
    height:17.596vw;
    max-height: 195px;
    min-height: 175px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include wide {
      transition: .3s transform;
    }
  }
  a:hover & {
    img {
      @include wide {
        transform: scale(1.03,1.03);
      }
    }
  }
}
.primary-article__body {
  padding-top: sp-vw(36);
  @include wide {
    padding-top: 20px;
  }
}
.primary-article__meta {
  display: flex;
  align-items: center;
  margin-bottom: sp-vw(10);
  @include wide {
    margin-bottom: 8px;
  }
  .article-label {
    margin-right: sp-vw(24);
    @include wide {
      margin-right: 12px;
    }
  }
}

