.inner {
	padding:0 $side-padding-sp;
	@include wide {
		width: 100%;
		max-width:calc(#{$min-width} + #{$side-padding-pc} * 2);
		padding-left:$side-padding-pc;
		padding-right:$side-padding-pc;
		margin-left: auto;
		margin-right: auto;
	}
}