.inner-links {
	display: flex;
	justify-content: center;
	@include sp {
		margin-top: sp-vw(80);
		margin-bottom: sp-vw(120);
		flex-wrap:wrap;
		justify-content: start;
	}
	@include wide {
		margin-top: 90px;
		margin-bottom: 95px;
	}
	&--pc-column3 {
		@include wide {
			max-width: 700px;
			margin-right: auto;
			margin-left: auto;
			flex-wrap: wrap;
			justify-content: flex-start;
			gap:8px;
		}
	}
	&.inner {
			padding: 0 sp-vw(7) 0 sp-vw(8);

			@include wide {
				padding-left: 0;
				padding-right: 0;
			}
	}
}
.inner-links__item {
	@include sp {
		width: 50%;
		height: sp-vw(105);
	}
	@include wide {
		min-height: 49px;
		min-width: 160px;
		&:not(:last-child) {
			margin-right: 15px;
		}
	}
	.inner-links--pc-column3 & {
		@include wide {
			width: calc((100% - 8px * 2) / 3);
			margin-right: 0;
		}
	}
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		color:$main-color3;
		border:1px solid $main-color3;
		height: 100%;
		font-size: 1.4rem;
		line-height: 1.3;
		letter-spacing: 0.04em;
		position: relative;
		padding-right: sp-vw(42);
		text-align: center;
		@include wide {
			transition: .3s;
			border-radius: 999px;
			padding-left: 26px;
			padding-right: 43px;
			padding-top: 10px;
			padding-bottom: 10px;
			&:hover {
				background: $main-color3;
				color: $bg-color1;
			}
		}
		&:after {
			content: '';
			display: block;
			border:1px solid $text-color2;
			border-width: 0 1px 1px 0;
			transform: rotate(45deg) translate(-50%,-50%);
			position: absolute;
			top: 50%;
			right: sp-vw(26);
			width: sp-vw(14);
			height: sp-vw(14);
			@include wide {
				width: 7px;
				height: 7px;
				right: 20px;
			}
		}
		&:hover:after {
			@include wide {
				border-color:$bg-color1;
			}
		}
	}
	@include sp {
		&:nth-child(even) a {
			border-left: none;
		}
		&:nth-child(n+3) a {
			border-top:none;
		}
	}
}